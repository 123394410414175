import { lazy } from "react";

const TutorPages = [
  {
    path: "/IS/login",
    component: lazy(() => import("../../view/auth/login/InternalSystem/index")),
    layout: "FullLayout",
  },
  {
    path: "/IS/register",
    component: lazy(
      () => import("../../view/auth/register/InternalSystem/index"),
    ),
    layout: "FullLayout",
  },
  {
    path: "/IS/recover-password",
    component: lazy(
      () => import("../../view/auth/recover-password/InternalSystem/index"),
    ),
    layout: "FullLayout",
  },
  {
    path: "/IS/reset-password",
    component: lazy(
      () => import("../../view/auth/reset-password/InternalSystem/index"),
    ),
    layout: "FullLayout",
  },

  // TUTOR Auth routes
  {
    path: "/tutor/register",
    component: lazy(() => import("../../view/auth/register/tutor")),
    layout: "FullLayout",
  },
  {
    path: "/tutor/login",
    component: lazy(() => import("../../view/auth/login/tutor")),
    layout: "FullLayout",
  },
  {
    path: "/tutor/recover-password",
    component: lazy(
      () => import("../../view/auth/recover-password/tutor/index"),
    ),
    layout: "FullLayout",
  },
  {
    path: "/tutor/reset-password",
    component: lazy(() => import("../../view/auth/reset-password/tutor/index")),
    layout: "FullLayout",
  },

  // PARENT Auth routes
  {
    path: "/parent/register",
    component: lazy(() => import("../../view/auth/register/parent/index")),
    layout: "FullLayout",
  },
  {
    path: "/parent/login",
    component: lazy(() => import("../../view/auth/login/parent/index")),
    layout: "FullLayout",
  },
  {
    path: "/parent/recover-password",
    component: lazy(
      () => import("../../view/auth/recover-password/parent/index"),
    ),
    layout: "FullLayout",
  },
  {
    path: "/parent/reset-password",
    component: lazy(
      () => import("../../view/auth/reset-password/parent/index"),
    ),
    layout: "FullLayout",
  },

  // STUDENT Auth routes
  {
    path: "/student/register",
    component: lazy(() => import("../../view/auth/register/student/index")),
    layout: "FullLayout",
  },
  {
    path: "/student/login",
    component: lazy(() => import("../../view/auth/login/student/index")),
    layout: "FullLayout",
  },
  {
    path: "/student/recover-password",
    component: lazy(
      () => import("../../view/auth/recover-password/student/index"),
    ),
    layout: "FullLayout",
  },
  {
    path: "/student/reset-password",
    component: lazy(
      () => import("../../view/auth/reset-password/student/index"),
    ),
    layout: "FullLayout",
  },
];

export default TutorPages;
