import { Col, Row, Skeleton, Breadcrumb, Button, message } from "antd";
import {
  EMPTY_VALUE,
  INVALID_TRANSLATION,
  SESSION_DATE_TIME_FORMAT,
  WEB_APP_URL,
} from "../../utils/const";
import EditBtn from "./EditBtn";
import { useIntl } from "react-intl";
import { useQueryHook } from "../../hooks/useQueyHook";
import {
  MatchingNotPaired,
  addSession,
  updateTutorAvailability,
} from "../../helpers/IS_api";
import { useMutation } from "react-query";
import {
  addMessage,
  generateSessionId,
  sessionURL,
  splitURL,
} from "../../helpers/helpers";
import { RiCheckboxCircleLine, RiCloseCircleLine } from "react-icons/ri";
import { useState } from "react";
import { Console, FormatToSessionDate, Translation } from "../../utils";
import { useHistory } from "react-router-dom";

type TableItemViewHeaderProps = {
  payload: any;
  page: string;
  loading: boolean;
  collection: string;
  collectionItem: string;
  editPath: string;
  allowEdit?: boolean;
};

export default function TableItemViewHeader(props: TableItemViewHeaderProps) {
  const {
    page,
    loading,
    collection,
    collectionItem,
    editPath,
    allowEdit = true,
    payload,
  } = props;
  const intl = useIntl();
  const history = useHistory();
  const [protocol, host] = splitURL(WEB_APP_URL);
  const sessionPlaceholder = Translation("session") || INVALID_TRANSLATION;
  const operationPlaceholder =
    Translation("something-went-wrong-while-trying-to-add") ||
    INVALID_TRANSLATION;
  const [studentsToBeMatched, setStudentsToBeMatched] = useState<any[]>([]);
  const [nextAvailability, setNextAvailability] = useState<number>(0);

  const emptySlot = payload?.tutorPreferences?.availability
    .filter((slot: any) => !slot.taken)
    .sort((a: any, b: any) => a.dayOfTheWeek - b.dayOfTheWeek);
  useQueryHook({
    queryKey: [
      "not-paired",
      payload?.userId,
      {
        gender: payload?.gender,
        levelId:
          payload?.tutorPreferences?.assignment?.level?.length > 0
            ? payload?.tutorPreferences?.assignment?.level?.map(
                (level: any) => level.levelId
              )
            : [],
        sectorId:
          payload?.tutorPreferences?.assignment?.sectors?.length > 0
            ? payload?.tutorPreferences?.assignment?.sectors.map(
                (sector: any) => sector.sectorId
              )
            : [],
        departmentId:
          payload?.tutorPreferences?.assignment?.department?.departmentId,
        tutorAvailability: emptySlot?.[nextAvailability],
        subjects: payload?.tutorPreferences?.subjectToTeach,
        filter: {
          mode: "tutor",
          program: payload?.tutorPreferences?.program?.programId,
          status: "unmatched",
        },
        search: "",
        program: payload?.tutorPreferences?.program?.programId,
      },
      payload,
    ],
    queryFn: MatchingNotPaired,
    queryOptions: {
      onSuccess: ({ data: { data, status } }: any) => {
        if (status === "failed" && data === "No student found") {
          setStudentsToBeMatched([]);
          setNextAvailability((prev) => prev + 1);
          return;
        }

        setStudentsToBeMatched(data);
      },
      onError: (error: any) => {
        Console(error);
      },
      enabled: payload?.userRole === "tutor",
    },
  });
  const mutation = useMutation(addSession, {
    onSuccess: () => {
      message.success({
        content: addMessage(sessionPlaceholder, intl),
        icon: <RiCheckboxCircleLine className="remix-icon" />,
      });
    },

    onError: (error: any) => {
      Console(error);

      message.error({
        content: `${operationPlaceholder} ${sessionPlaceholder}`,
        icon: <RiCloseCircleLine className="remix-icon" />,
      });
    },
  });

  const handleCreateRecurringSession = async () => {
    const { tutorPreferences, userId, contactDetails } = payload;
    if (emptySlot.length === 0) {
      message.error("No empty slot available");
      return;
    }
    const nextSlot = emptySlot?.[nextAvailability];
    const { startDate, endDate } = FormatToSessionDate(nextSlot);
    const studentToBeMatchedBasedOnAvailability = studentsToBeMatched?.filter(
      (student: any) =>
        student.availability.some(
          (availability: any) =>
            availability.dayOfTheWeek === nextSlot?.dayOfTheWeek &&
            availability.startTime === nextSlot?.startTime &&
            availability.endTime === nextSlot?.endTime
        )
    );
    if (studentToBeMatchedBasedOnAvailability.length === 0) {
      message.error("No students available for the selected slot");
      return;
    }

    const sessionId = generateSessionId();
    const sessionLink = protocol + sessionURL(host, sessionId);
    const sessionData = {
      sessionId: sessionId,
      program: tutorPreferences?.program?.programId,
      sessionType: "private-session",
      status: "session-0-to-be-scheduled",
      tutors: [
        {
          userId: userId,
          fullName: `${contactDetails?.firstName} ${contactDetails?.lastName}`,
          absence: false,
        },
      ],
      students: [
        {
          userId: studentToBeMatchedBasedOnAvailability[0].studentId,
          fullName: studentToBeMatchedBasedOnAvailability[0].studentFullName,
          absence: false,
        },
      ],
      vsc: [{ fullName: "", userId: "" }],
      school: "Autre",
      placeOrRoom: "",
      scheduleDuringHolidays: false,
      sessionLink: sessionLink,
      sessionDate: {
        startDate,
        endDate,
      },
      recurrence: { recurrenceNumber: 0, recurrenceName: "weekly" },
    };
    mutation.mutate({ sessionData });
    await updateTutorAvailability({
      tutorId: userId,
      availabilityId: emptySlot?.[nextAvailability]._id,
      taken: true,
    });
    history.push(`/tutoring/sessions`);
  };

  return (
    <>
      <Row className="hp-mb-40" align="middle" justify="space-between">
        <Col>
          <Breadcrumb className="hp-large-breadcrumb">
            <Breadcrumb.Item>
              <span className="hp-text-color-black-60">
                {collection || EMPTY_VALUE}
              </span>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              {loading && <Skeleton.Input active={true} />}
              {!loading && (
                <span className="hp-text-color-primary-1">
                  {collectionItem || EMPTY_VALUE}
                </span>
              )}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <div
        style={{
          display: "flex",
          gap: "2rem",
          marginBottom: "2rem",
        }}
      >
        {allowEdit && (
          <Row className="hp-mb-10" align="middle" justify="space-between">
            <Col>
              <EditBtn page={page} path={editPath} />
            </Col>
          </Row>
        )}
        {payload?.userRole === "tutor" && (
          <Button
            type="primary"
            loading={mutation.isLoading}
            disabled={
              payload?.tutorPreferences?.onBoardingStep < Number("3") ||
              payload?.tutorPreferences?.matchedStudents?.length ===
                payload?.tutorPreferences?.assignment
                  ?.numberOfStudentsToSupport ||
              studentsToBeMatched.length === 0
            }
            onClick={() => handleCreateRecurringSession()}
          >
            Affectation automatique ({" "}
            {payload?.tutorPreferences?.matchedStudents?.length} /{" "}
            {payload?.tutorPreferences?.assignment?.numberOfStudentsToSupport} )
          </Button>
        )}
      </div>
    </>
  );
}
