import { INVALID_TRANSLATION } from "../utils/const";

export default function Translations(intl: any) {
  const Translate = (id: string) =>
    intl.formatMessage({ id: id }) || INVALID_TRANSLATION;

  return {
    STUDENT_TRANSLATION: Translate("student"),
    TUTOR_TRANSLATION: Translate("tutor"),
    VSC_TRANSLATION: Translate("vsc"),
    SC_TRANSLATION: Translate("sc"),
    CR_TRANSLATION: Translate("cr"),
    CR_REPORT_TRANSLATION: Translate("cr-report"),
    SC_REPORT_TRANSLATION: Translate("sc-report"),
    SC_REPORTS_TRANSLATION: Translate("sc-reports"),
    CR_REPORTS_TRANSLATION: Translate("cr-reports"),
    ON_UPDATE_FAILED: Translate("something-went-wrong-while-trying-to-update"),
  };
}
