import navigation from "../../../../navigation/vertical";

import { useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { UserRoles } from "../../../../utils/const";
import { useState, useEffect } from "react";

const { SubMenu } = Menu;

export default function MenuItem(props: any) {
  const location = useLocation();

  let navItems: any = [];
  const [openKeys, setOpenKeys] = useState<any>([]);

  const { onClose } = props;
  const { pathname } = location;

  // @ts-ignore
  const user = JSON.parse(localStorage.getItem("user"));

  switch (user?.userRole) {
    case UserRoles.TUTOR:
      navItems = navigation.tutor;
      break;
    case UserRoles.PARENT:
      navItems = navigation.parent;
      break;
    case UserRoles.STUDENT:
      navItems = navigation.student;
      break;
    case UserRoles.ADMIN:
      navItems = navigation.admin;
      break;
    case UserRoles.MANAGER:
      navItems = navigation.manager;
      break;
    case UserRoles.COORDINATOR:
      navItems = navigation.coordinator;
      break;
    case UserRoles.VSC:
      navItems = navigation.vsc;
      break;
    default:
      navItems = [];
  }

  // @ts-ignore
  const customise = useSelector((state) => state.customise);
  const splitLocation = pathname.split("/");

  const menuItem = navItems.map((item: any, index: number) => {
    if (item.header) return <Menu.ItemGroup key={index} title={item.header} />;

    if (item.children) {
      return (
        <SubMenu
          key={item.id}
          icon={item.icon}
          title={item.title}
          onTitleClick={() =>
            setOpenKeys((prevOpenKeys: any) =>
              prevOpenKeys.includes(item.id)
                ? prevOpenKeys.filter((key: any) => key !== item.id)
                : [...prevOpenKeys, item.id],
            )
          }
        >
          {item.children.map((itemChildren: any) => {
            if (!itemChildren.children) {
              const childrenNavLink = itemChildren.navLink.split("/");

              const isMatch = childrenNavLink.map(
                (link: any, index: string | number) => {
                  return link === openKeys[index];
                },
              );

              const allMatch = isMatch.every(
                (match: boolean) => match === true,
              );

              return (
                <Menu.Item
                  key={itemChildren.id}
                  onClick={onClose}
                  className={
                    allMatch
                      ? "ant-menu-item-selected"
                      : "ant-menu-item-selected-in-active"
                  }
                  style={
                    allMatch
                      ? {
                          background: "#F0F1F5",
                          fontWeight: "600",
                          color: "red",
                        }
                      : { background: "none" }
                  }
                >
                  <Link style={{ color: "#FF6B43" }} to={itemChildren.navLink}>
                    {itemChildren.title}
                  </Link>
                </Menu.Item>
              );
            } else {
              return (
                <SubMenu key={itemChildren.id} title={itemChildren.title}>
                  {itemChildren.children.map((childItem: any) => {
                    const childrenItemLink = childItem.navLink.split("/");

                    const isMatch = childrenItemLink.map(
                      (link: any, index: string | number) => {
                        return link === openKeys[index];
                      },
                    );

                    const allMatch = isMatch.every(
                      (match: boolean) => match === true,
                    );

                    return (
                      <Menu.Item
                        key={childItem.id}
                        onClick={onClose}
                        className={
                          allMatch
                            ? "ant-menu-item-selected"
                            : "ant-menu-item-selected-in-active"
                        }
                      >
                        <Link to={childItem.navLink}>{childItem.title}</Link>
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            }
          })}
        </SubMenu>
      );
    } else {
      const itemNavLink = item.navLink ? item.navLink.split("/") : "";
      const isMatch =
        splitLocation[splitLocation.length - 2] +
          "/" +
          splitLocation[splitLocation.length - 1] ===
        itemNavLink[itemNavLink.length - 2] +
          "/" +
          itemNavLink[itemNavLink.length - 1];

      return (
        <Menu.Item
          key={item.id}
          icon={item.icon}
          onClick={onClose}
          style={item.tag && { pointerEvents: "none" }}
          className={
            isMatch
              ? "ant-menu-item-selected"
              : "ant-menu-item-selected-in-active"
          }
        >
          <Link to={item.navLink}>{item.title}</Link>
        </Menu.Item>
      );
    }
  });

  useEffect(() => setOpenKeys(splitLocation), [location]);

  return (
    <Menu
      mode="inline"
      theme={customise.theme == "light" ? "light" : "dark"}
      openKeys={openKeys}
    >
      {menuItem}
    </Menu>
  );
}
