// import useLocalStorage from "../hooks/useLocalStorage";

import { FC } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { allowed_routes, allowed_routes_end_slash } from "./allowedRoutes";
import { PrivateRouteProps } from "../ts/types";
import { UserCreatedBy, UserRoles, UserStatus } from "../utils/const";
import { UserData, useAuthContext } from "../context/AuthContext";
import { useLocalStorage } from "usehooks-ts";

const PrivateRoute: FC<PrivateRouteProps> = ({
  path,
  redirectPath,
  ...rest
}) => {
  const currentLocation = useLocation();

  const [user] = useLocalStorage<UserData | null>("user", null);
  const shouldRedirectTutor =
    user?.userRole === UserRoles.TUTOR &&
    user?.status !== UserStatus.ACTIVE &&
    user?.createdBy !== UserCreatedBy.ADMIN &&
    user?.status !== UserStatus.COMMITMENT_END &&
    user?.status !== UserStatus.PREEMPTIVE_END;

  const shouldRedirectParentToOnboarding =
    user?.userRole === UserRoles.PARENT && user?.status === UserStatus.ON_HOLD;
  //  &&
  // user?.parentHasChildren;

  const shouldRedirectParentToChildren =
    user?.userRole === UserRoles.PARENT &&
    user?.status !== UserStatus.ACTIVE &&
    user?.parentHasChildren;
  const shouldRedirectToChildrenWhenNotActiveAndHaveChildren =
    user?.userRole === UserRoles.PARENT &&
    user?.status !== UserStatus.ACTIVE &&
    user?.parentHasChildren;
  let returnedRoute: any = null;
  // Check if tutor should redirect to onboarding page

  if (shouldRedirectTutor) {
    returnedRoute = (
      <>
        <Redirect to={`/${UserRoles.TUTOR}/on-boarding`} />
        <Route {...rest} />
      </>
    );

    return returnedRoute;
  }
  // if (shouldRedirectToChildrenWhenNotActiveAndHaveChildren) {
  //   returnedRoute = (
  //     <>
  //       <Redirect to={`/${UserRoles.PARENT}/children`} />
  //       <Route {...rest} />
  //     </>
  //   );
  //   return returnedRoute;
  // }

  // Check if parent should redirect to onboarding page
  if (shouldRedirectParentToOnboarding) {
    // location.reload();
    returnedRoute = (
      <>
        <Redirect to={`/${UserRoles.PARENT}/on-boarding`} />

        <Route {...rest} />
      </>
    );
    return returnedRoute;
  }

  if (shouldRedirectParentToChildren) {
    returnedRoute = (
      <>
        <Redirect to={`/${UserRoles.PARENT}/children`} />
        <Route {...rest} />
      </>
    );
    return returnedRoute;
  }

  // render routes
  if (
    !user?.userId &&
    (allowed_routes.includes(currentLocation.pathname) ||
      allowed_routes_end_slash.includes(currentLocation.pathname))
  )
    returnedRoute = <Route {...rest} />;
  else if (
    user?.userId &&
    (allowed_routes.includes(currentLocation.pathname) ||
      allowed_routes_end_slash.includes(currentLocation.pathname))
  )
    returnedRoute = <Redirect to={{ pathname: "/" }} />;
  else if (user?.userId) returnedRoute = <Route {...rest} />;
  else returnedRoute = <Redirect to={{ pathname: redirectPath }} />;
  return returnedRoute;
};

export default PrivateRoute;
