import { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Tabs, Spin } from "antd";
import { Translation } from "../../utils";
import { INVALID_TRANSLATION } from "../../utils/const";
import { LoadingOutlined } from "@ant-design/icons";
import {
  TutorsIcon,
  StudentsIcon,
  MatchingIcon,
  ClockIcon,
  ReportsIcon,
} from "../icons";
import { getAdminStats } from "../../helpers/IS_api";
import { useLocalStorage } from "usehooks-ts";
import { useQueryHook } from "../../hooks/useQueyHook";
const { TabPane } = Tabs;

export default function DashboardNavigation() {
  const [category] = useState("all");
  const [user] = useLocalStorage<any>("user", {});
  const { data, isLoading } = useQueryHook({
    queryKey: ["dashboard-stats", user?.userId],
    queryFn: getAdminStats,
    queryOptions: {},
  });
  const isTutoraSoldaire = user?.administrationPreferences?.program.some(
    (prog: any) => prog.programId === "az-fac-5f87232"
  );
  const isSuperAdmin = user?.userRole === "super_administrator";
  const isAdmin =
    user?.userRole === "manager" ||
    user?.userRole === "coordinator" ||
    user?.userRole === "vsc";
  const allowedToViewData = (isAdmin && !isTutoraSoldaire) || isSuperAdmin;

  const tutorsPlaceholder = Translation("tutors") || INVALID_TRANSLATION;
  const parentsPlaceholder = Translation("parents") || INVALID_TRANSLATION;
  const studentsPlaceholder = Translation("students") || INVALID_TRANSLATION;
  const matchingPlaceholder = Translation("matching") || INVALID_TRANSLATION;
  const sessionsPlaceholder = Translation("sessions") || INVALID_TRANSLATION;
  const reportsPlaceholder = Translation("reports") || INVALID_TRANSLATION;
  const managePlaceholder = Translation("manage") || INVALID_TRANSLATION;
  const components = [
    {
      category: "general",
      list: [
        {
          icon: <TutorsIcon width="25px" />,
          title: tutorsPlaceholder,
          subtitle: managePlaceholder + " " + tutorsPlaceholder,
          link: "/manage/tutors",
          count:
            (!isLoading &&
              data?.data?.data?.find((item: any) => item._id === "tutors")
                ?.count) ||
            0,
        },
      ],
    },
    {
      category: "navigation",
      list: [
        {
          icon: <TutorsIcon width="23px" />,
          title: parentsPlaceholder,
          subtitle: managePlaceholder + " " + parentsPlaceholder,
          link: "/manage/parents",
          count:
            data?.data?.data.find((item: any) => item._id === "parent")
              ?.count || 0,
        },
        {
          icon: <StudentsIcon width="23px" />,
          title: studentsPlaceholder,
          subtitle: managePlaceholder + " " + studentsPlaceholder,
          link: "/manage/students",
          count:
            data?.data?.data.find((item: any) => item._id === "student")
              ?.count || 0,
        },
        {
          icon: <ClockIcon width="23px" />,
          title: sessionsPlaceholder,
          subtitle: managePlaceholder + " " + sessionsPlaceholder,
          link: "/tutoring/sessions",
          count:
            data?.data?.data.find((item: any) => item._id === "sessions")
              ?.count || 0,
        },
        {
          icon: <MatchingIcon width="23px" />,
          title: matchingPlaceholder,
          subtitle: managePlaceholder + " " + matchingPlaceholder,
          link: "/manage/matching",
        },
        {
          icon: <ReportsIcon width="23px" />,
          title: reportsPlaceholder,
          subtitle: managePlaceholder + " " + reportsPlaceholder,
          link: "/tutoring/cr-reports",
        },
      ],
    },
  ];

  function cardItem(item: any) {
    return (
      <Link to={item.link}>
        <Card
          bodyStyle={{ padding: 16 }}
          className="hp-border-radius-xl hp-components-item hp-border-none hp-bg-dark-100"
        >
          <Row
            align="bottom"
            justify="space-between"
            style={{ marginLeft: "10px" }}
          >
            <Col span={16}>
              {item.icon}
              <h4
                style={{ marginTop: "20px" }}
                className="hp-components-item-title hp-mb-8 hp-text-color-black-bg hp-text-color-dark-0"
              >
                {item.title}
              </h4>
              <p className="hp-mb-0 hp-caption hp-font-weight-400 hp-text-color-black-80 hp-text-color-dark-30">
                {item.subtitle}
              </p>
            </Col>
            <Col>
              {isLoading ? (
                <Spin
                  //@ts-ignore
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                <h4>{item.count}</h4>
              )}
            </Col>
          </Row>
        </Card>
      </Link>
    );
  }

  return (
    <Row gutter={[0, 48]} className="hp-mb-48">
      <Col span={24} className="hp-px-sm-0 hp-px-md-480">
        <Tabs
          defaultActiveKey={"all"}
          activeKey={category}
          tabBarStyle={{ display: "none" }}
        >
          <TabPane key={"all"}>
            <Row gutter={[24, 24]}>
              {components.map((component) =>
                component.list.map((item, index) => {
                  if (
                    !allowedToViewData &&
                    (item.title === parentsPlaceholder ||
                      item.title === matchingPlaceholder)
                  ) {
                    return null;
                  } else {
                    return (
                      <Col sm={12} span={24} key={component.category + index}>
                        {cardItem(item)}
                      </Col>
                    );
                  }
                })
              )}
            </Row>
          </TabPane>

          {components.map((component) => (
            <TabPane key={component.category}>
              <Row gutter={[24, 24]}>
                {component.list.map((item, index) => (
                  <Col sm={12} span={24} key={component.category + index}>
                    {cardItem(item)}
                  </Col>
                ))}
              </Row>
            </TabPane>
          ))}
        </Tabs>
      </Col>
    </Row>
  );
}
