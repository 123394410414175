import moment from "moment";
import { SESSION_DATE_TIME_FORMAT } from "./const";
const now = moment();

export const CSVFormat = () => {
  const formattedDateTime = now.format("DD-MM-YYYY").toLowerCase();
  const randomNumber = Math.floor(Math.random() * 100) + 1;

  return `X${formattedDateTime}X-${randomNumber}`;
};

export const FormatDateToDay = (date: string) => moment(date).format("DD");
export const FormatDateToHoursAndMinutes = (date: string) =>
  moment(date).format("HH:mm");
export const FormatDateToHours = (date: string) => moment(date).format("HH");
export const FormatDateToMinutes = (date: string) => moment(date).format("mm");
export const FormatToEuropeDate = (date: string) =>
  moment(date).format("DD MMMM, YYYY");
export const FormatToEuropeDateHours = (date: string) =>
  moment(date).format("DD MMMM, YYYY HH:mm");
export const FormatToEnglishDate = (date: string) =>
  moment(date, "DD/MM/YYYY").format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (ZZZ)");
export const FormatToEuropeDateCsv = (date: string) =>
  moment(date).format("DD-MMMM-YYYY");
export const ExtractYearFromDate = (date: string) =>
  moment(date, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (ZZZ)").format("YYYY");

export const FormatToSessionDate = (date: any) => {
  return {
    startDate: moment()
      .set({
        day: parseInt(date?.dayOfTheWeek) + 1,
        hour: parseInt(date?.startTime.split(":")[0]),
        minute: parseInt(date?.startTime.split(":")[1]),
        second: 0,
      })
      .format(SESSION_DATE_TIME_FORMAT),
    endDate: moment()
      .set({
        day: parseInt(date?.dayOfTheWeek) + 1,
        hour: parseInt(date?.endTime.split(":")[0]),
        minute: parseInt(date?.endTime.split(":")[1]),
        second: 0,
      })
      .format(SESSION_DATE_TIME_FORMAT),
  };
};

export const FormatSingleSessionDate = (date: any) => {
  const today = moment();
  const alreadyPassed = moment(date).isBefore(today);
  if (alreadyPassed) {
    return {
      startDate: today
        .add(7, "days")
        .set({
          day: parseInt(date?.dayOfTheWeek) + 1,
          hour: parseInt(date?.startTime.split(":")[0]),
          minute: parseInt(date?.startTime.split(":")[1]),
          second: 0,
        })
        .format(SESSION_DATE_TIME_FORMAT),
      endDate: today
        .set({
          day: parseInt(date?.dayOfTheWeek) + 1,
          hour: parseInt(date?.endTime.split(":")[0]),
          minute: parseInt(date?.endTime.split(":")[1]),
          second: 0,
        })
        .format(SESSION_DATE_TIME_FORMAT),
    };
  } else {
    return {
      startDate: today
        .set({
          day: parseInt(date?.dayOfTheWeek) + 1,
          hour: parseInt(date?.startTime.split(":")[0]),
          minute: parseInt(date?.startTime.split(":")[1]),
          second: 0,
        })
        .format(SESSION_DATE_TIME_FORMAT),
      endDate: today
        .set({
          day: parseInt(date?.dayOfTheWeek) + 1,
          hour: parseInt(date?.endTime.split(":")[0]),
          minute: parseInt(date?.endTime.split(":")[1]),
          second: 0,
        })
        .format(SESSION_DATE_TIME_FORMAT),
    };
  }
};
