import useLocalStorage from "../../../hooks/useLocalStorage";

import { Col } from "antd";
import { FormattedMessage } from "react-intl";
import { EMPTY_VALUE } from "../../../utils/const";

export default function HeaderText() {
  const [user] = useLocalStorage("user", {});

  return (
    <Col xl={16} lg={14} className="hp-header-left-text hp-d-flex-center">
      <h3 className="hp-header-left-text-item hp-text-color-black-100 hp-text-color-dark-0 hp-mb-0">
        <FormattedMessage id="welcome" />,{" "}
        {user?.contactDetails?.firstName || EMPTY_VALUE}{" "}
        {user?.contactDetails?.lastName || EMPTY_VALUE} 👋
      </h3>
    </Col>
  );
}
