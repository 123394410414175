import { lazy } from "react";
import { RedirectPaths, UserRoles } from "../../utils/const";

const TutorPages = [
  {
    path: "/",
    component: lazy(() => import("../../view/tutor/dashboard/index")),
    layout: "VerticalLayout",
    redirectPath: RedirectPaths.TUTOR_LOGIN,
  },
  {
    path: `/${UserRoles.TUTOR}/on-boarding`,
    component: lazy(() => import("../../view/tutor/onBoarding/index")),
    layout: "VerticalLayout",
    redirectPath: RedirectPaths.TUTOR_LOGIN,
  },
  {
    path: `/${UserRoles.TUTOR}/sessions`,
    component: lazy(() => import("../../view/tutor/sessions/index")),
    layout: "VerticalLayout",
    redirectPath: RedirectPaths.TUTOR_LOGIN,
  },
  {
    path: `/${UserRoles.TUTOR}/students`,
    component: lazy(() => import("../../view/tutor/students/index")),
    layout: "VerticalLayout",
    redirectPath: RedirectPaths.TUTOR_LOGIN,
  },
  {
    path: `/${UserRoles.TUTOR}/reports`,
    component: lazy(() => import("../../view/tutor/reports/index")),
    layout: "VerticalLayout",
    redirectPath: RedirectPaths.TUTOR_LOGIN,
  },
  {
    path: `/${UserRoles.TUTOR}/reports/:sessionId`,
    component: lazy(() => import("../../view/tutor/reports/index")),
    layout: "VerticalLayout",
    redirectPath: RedirectPaths.TUTOR_LOGIN,
  },
  {
    path: `/${UserRoles.TUTOR}/account`,
    component: lazy(() => import("../../view/tutor/account/index")),
    layout: "VerticalLayout",
    redirectPath: RedirectPaths.TUTOR_LOGIN,
  },
  {
    path: `/${UserRoles.TUTOR}/documents`,
    component: lazy(() => import("../../view/tutor/documents/index")),
    layout: "VerticalLayout",
    redirectPath: RedirectPaths.TUTOR_LOGIN,
  },
  {
    path: `/${UserRoles.TUTOR}/equipment`,
    component: lazy(() => import("../../view/tutor/equipment/index")),
    layout: "VerticalLayout",
    redirectPath: RedirectPaths.STUDENT_LOGIN,
  },
  {
    path: `/${UserRoles.TUTOR}/training-path`,
    component: lazy(() => import("../../view/tutor/trainingPath/index")),
    layout: "VerticalLayout",
    redirectPath: RedirectPaths.TUTOR_LOGIN,
  },
  {
    path: `/${UserRoles.TUTOR}/contact-zupdeco`,
    component: lazy(() => import("../../view/_shared/contact/index")),
    layout: "VerticalLayout",
    redirectPath: RedirectPaths.TUTOR_LOGIN,
  },
];

export default TutorPages;
