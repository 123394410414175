import {
  ReportsStatus,
  ReportsTypes,
  StatusOfEstablishment,
} from "../utils/const";

export const NumberOfStudentsList = Array.from(
  { length: 20 },
  (_, index) => index + 1
);

export const StrongAreas = (intl: any) => {
  return [
    { name: intl.formatMessage({ id: "learning" }), value: "learning" },
    { name: intl.formatMessage({ id: "motivation" }), value: "motivation" },
    { name: intl.formatMessage({ id: "organization" }), value: "organization" },
    {
      name: intl.formatMessage({ id: "self-confidence" }),
      value: "self-confidence",
    },
    { name: intl.formatMessage({ id: "self-esteem" }), value: "self-esteem" },
    { name: intl.formatMessage({ id: "self-control" }), value: "self-control" },
    {
      name: intl.formatMessage({ id: "self-discipline" }),
      value: "self-discipline",
    },
  ];
};

export const LevelOfStudents = (intl: any) => {
  return [
    { value: "b1", name: intl.formatMessage({ id: "level" }) + " B1" },
    { value: "b2", name: intl.formatMessage({ id: "level" }) + " B2" },
    { value: "c1", name: intl.formatMessage({ id: "level" }) + " B3" },
  ];
};

export const LevelOfStudentsSupporting = [
  { levelCode: "1", levelName: "1 Student" },
  { levelCode: "2", levelName: "2 Students" },
  { levelCode: "3", levelName: "3 Students" },
  { levelCode: "4", levelName: "4 Students" },
  { levelCode: "5", levelName: "5 Students" },
  { levelCode: "6", levelName: "6 Students" },
  { levelCode: "7", levelName: "7 Students" },
  { levelCode: "8", levelName: "8 Students" },
  { levelCode: "9", levelName: "9 Students" },
  { levelCode: "10", levelName: "10 Students" },
];

export const TutoringCoursesList = (intl: any) => {
  return [
    {
      name: intl.formatMessage({ id: "sciences-et-technologies" }),
      value: "sciences-et-technologies",
    },
    {
      name: intl.formatMessage({ id: "lettres-les-langues-lart" }),
      value: "lettres-les-langues-lart",
    },
    {
      name: intl.formatMessage({ id: "sciences-humaines-et-sociales" }),
      value: "sciences-humaines-et-sociales",
    },
    {
      name: intl.formatMessage({ id: "droit-et-sciences-politiques" }),
      value: "droit-et-sciences-politiques",
    },
    {
      name: intl.formatMessage({ id: "economie-et-gestion" }),
      value: "economie-et-gestion",
    },
    { name: intl.formatMessage({ id: "sante" }), value: "sante" },
    { name: intl.formatMessage({ id: "sport" }), value: "sport" },
    { name: intl.formatMessage({ id: "staps" }), value: "staps" },
    {
      name: intl.formatMessage({ id: "sciences-de-leducation" }),
      value: "sciences-de-leducation",
    },
    {
      name: intl.formatMessage({ id: "sciences-de-la-vie-et-de-la-terre" }),
      value: "sciences-de-la-vie-et-de-la-terre",
    },
    {
      name: intl.formatMessage({ id: "sciences-pour-la-sante" }),
      value: "sciences-pour-la-sante",
    },
    {
      name: intl.formatMessage({ id: "sciences-pour-lingenieur" }),
      value: "sciences-pour-lingenieur",
    },
    {
      name: intl.formatMessage({ id: "sciences-du-langage" }),
      value: "sciences-du-langage",
    },
    { name: intl.formatMessage({ id: "sociologie" }), value: "sociologie" },
    {
      name: intl.formatMessage({ id: "communication-marketing" }),
      value: "communication-marketing",
    },
    { name: intl.formatMessage({ id: "commerce" }), value: "commerce" },
    {
      name: intl.formatMessage({ id: "eco-gestion-administration" }),
      value: "eco-gestion-administration",
    },
    {
      name: intl.formatMessage({ id: "mathematique-informatique" }),
      value: "mathematique-informatique",
    },
    {
      name: intl.formatMessage({ id: "histoire-geographie" }),
      value: "histoire-geographie",
    },
    { name: intl.formatMessage({ id: "psychologie" }), value: "psychologie" },
    { name: intl.formatMessage({ id: "journalisme" }), value: "journalisme" },
    { name: intl.formatMessage({ id: "autres" }), value: "autres" },
  ];
};

export const VscStudyLevelList = (intl: any) => {
  return [
    {
      name: intl.formatMessage({ id: "brevet-des-colleges" }),
      value: "brevet-des-colleges",
    },
    {
      name: intl.formatMessage({ id: "seconde-professionnelle" }),
      value: "seconde-professionnelle",
    },
    {
      name: intl.formatMessage({ id: "premiere-professionnelle" }),
      value: "premiere-professionnelle",
    },
    {
      name: intl.formatMessage({ id: "terminale-professionnelle" }),
      value: "terminale-professionnelle",
    },
    {
      name: intl.formatMessage({ id: "seconde-generale-et-technologique" }),
      value: "seconde-generale-et-technologique",
    },
    {
      name: intl.formatMessage({ id: "premiere-generale-et-technologique" }),
      value: "premiere-generale-et-technologique",
    },
    {
      name: intl.formatMessage({ id: "terminale-generale-et-technologique" }),
      value: "terminale-generale-et-technologique",
    },
    { name: intl.formatMessage({ id: "bac-1" }), value: "bac-1" },
    { name: intl.formatMessage({ id: "bac-2" }), value: "bac-2" },
    { name: intl.formatMessage({ id: "bac-3" }), value: "bac-3" },
    { name: intl.formatMessage({ id: "bac-4" }), value: "bac-4" },
    { name: intl.formatMessage({ id: "bac-5" }), value: "bac-5" },
  ];
};

export const SubjectsList = (intl: any) => {
  return [
    {
      value: "scientific-and-first",
      name: intl.formatMessage({ id: "scientific-and-first" }),
    },
    {
      value: "literary-and-first",
      name: intl.formatMessage({ id: "literary-and-first" }),
    },
    {
      value: "both-subjects",
      name: intl.formatMessage({ id: "both-subjects" }),
    },
  ];
};

export const GenderList = (intl: any, mode = "normal") => {
  if (mode === "child")
    return [
      { value: "male", name: intl.formatMessage({ id: "boy" }) },
      { value: "female", name: intl.formatMessage({ id: "girl" }) },
    ];
  else
    return [
      { value: "male", name: intl.formatMessage({ id: "male" }) },
      { value: "female", name: intl.formatMessage({ id: "female" }) },
    ];
};

export const TutoringTypesList = (intl: any) => {
  return [
    { value: "private", name: intl.formatMessage({ id: "private" }) },
    { value: "group", name: intl.formatMessage({ id: "group" }) },
  ];
};

export const MatchingTypesFilterStatus = (intl: any) => {
  return [
    {
      value: "unmatched",
      name: intl.formatMessage({ id: "unmatched-status" }),
    },
    {
      value: "private-session",
      name: intl.formatMessage({ id: "matching-confirmed-status" }),
    },
    // {
    //   value: "all-others",
    //   name: intl.formatMessage({ id: "other-matchings-status" }),
    // },
  ];
};

export const TruthAndFalseList = (intl: any) => {
  return [
    { value: true, name: intl.formatMessage({ id: "yes" }) },
    { value: false, name: intl.formatMessage({ id: "no" }) },
  ];
};

export const SessionsStatusList = (intl: any) => {
  return [
    {
      value: "canceled",
      name: intl.formatMessage({ id: "session-0-abandoned" }),
    },
    {
      value: "session-0-on-hold",
      name: intl.formatMessage({ id: "session-0-on-hold" }),
    },
    {
      value: "session-0-to-be-scheduled",
      name: intl.formatMessage({ id: "session-0-to-be-scheduled" }),
    },
    /* {
      value: "session-0-suggested",
      name: intl.formatMessage({ id: "session-0-suggested" }),
    },
    {
      value: "session-0-called-again",
      name: intl.formatMessage({ id: "session-0-called-again" }),
    },
    {
      value: "session-0-confirmed",
      name: intl.formatMessage({ id: "session-0-confirmed" }),
    },
    {
      value: "session-0-pair-ok",
      name: intl.formatMessage({ id: "session-0-pair-ok" }),
    },
    {
      value: "session-0-finish-stop",
      name: intl.formatMessage({ id: "session-0-finish-stop" }),
    },
    { value: "canceled", name: intl.formatMessage({ id: "canceled" }) },
    { value: "failed", name: intl.formatMessage({ id: "failed" }) },
    { value: "scheduled", name: intl.formatMessage({ id: "scheduled" }) },
    { value: "confirmed", name: intl.formatMessage({ id: "confirmed" }) }, */
  ];
};

export const ProgramsList = (intl: any): any => {
  return [
    {
      programId: "az-rem-4ca0f8d",
      programName: intl.formatMessage({ id: "face-to-face" }),
      valueUnformated: "HomeClasse",
    },
    {
      programId: "az-fac-5f87232",
      programName: intl.formatMessage({ id: "remote" }),
      valueUnformated: "Tutorat solidaire",
    },
    {
      programId: "az-zup-6g98343",
      programName: "ZUPdeFOOT",
      valueUnformated: "ZUPdeFOOT",
    },
  ];
};

export const CategoryList = (intl: any) => {
  return [
    {
      value: "elementary-school",
      name: intl.formatMessage({ id: "category-elementary-school" }),
    },
    {
      value: "middle-school",
      name: intl.formatMessage({ id: "category-middle-school" }),
    },
    {
      value: "high-school",
      name: intl.formatMessage({ id: "category-high-school" }),
    },
    { value: "other", name: intl.formatMessage({ id: "category-other" }) },
  ];
};

export const CategoryOtherList = (intl: any) => {
  return [
    { value: "company", name: intl.formatMessage({ id: "category-company" }) },
    {
      value: "association",
      name: intl.formatMessage({ id: "category-association" }),
    },
    { value: "other", name: intl.formatMessage({ id: "category-other" }) },
  ];
};

export const StructureTypes = (intl: any) => {
  return [
    {
      label: intl.formatMessage({ id: "educational-establishment" }),
      options: [
        {
          value: 1,
          name: "elementary-school",
          label: intl.formatMessage({ id: "category-elementary-school" }),
        },
        {
          value: 2,
          name: "middle-school",
          label: intl.formatMessage({ id: "category-middle-school" }),
        },
        {
          value: 3,
          name: "high-school",
          label: intl.formatMessage({ id: "category-high-school" }),
        },
      ],
    },
    {
      label: intl.formatMessage({ id: "higher-school-list" }),
      options: [
        {
          value: 4,
          name: "business-school",
          label: intl.formatMessage({ id: "business-school" }),
        },
        { value: 6, name: "iut", label: intl.formatMessage({ id: "iut" }) },

        {
          value: 7,
          name: "university",
          label: intl.formatMessage({ id: "education_university" }),
        },
        { value: 8, name: "ufr", label: intl.formatMessage({ id: "ufr" }) },
        {
          value: 5,
          name: "engineering-school",
          label: intl.formatMessage({ id: "engineering-school" }),
        },
      ],
    },

    {
      label: intl.formatMessage({ id: "other" }),
      options: [
        {
          value: 9,
          name: "company",
          label: intl.formatMessage({ id: "category-company" }),
        },
        {
          value: 10,
          name: "association",
          label: intl.formatMessage({ id: "category-association" }),
        },
        {
          value: 11,
          name: "other",
          label: intl.formatMessage({ id: "other" }),
        },
      ],
    },
  ];
};

export const MeasuresOfSuccess = (intl: any) => {
  return [
    {
      value: "bad",
      name: intl.formatMessage({ id: "bad" }),
      valueUnformated: "Bad",
    },
    {
      value: "average",
      name: intl.formatMessage({ id: "average" }),
      valueUnformated: "Average",
    },
    {
      value: "good",
      name: intl.formatMessage({ id: "good" }),
      valueUnformated: "Good",
    },
    {
      value: "very–good",
      name: intl.formatMessage({ id: "very–good" }),
      valueUnformated: "Very good",
    },
  ];
};

export const EstablishmentPriority = (intl: any) => {
  return [
    {
      value: "non-priority",
      name: intl.formatMessage({ id: "priority-non-priority" }),
      valueUnformated: "Non-priority",
    },
    {
      value: "college-cappe",
      name: intl.formatMessage({ id: "priority-college-cappe" }),
      valueUnformated: "college CAPPE",
    },
    {
      value: "city-policy",
      name: intl.formatMessage({ id: "priority-city-policy" }),
      valueUnformated: "City policy",
    },
    {
      value: "rep",
      name: intl.formatMessage({ id: "priority-rep" }),
      valueUnformated: "REP",
    },
    {
      value: "rep-plus",
      name: intl.formatMessage({ id: "priority-rep-plus" }),
      valueUnformated: "REP+",
    },
    {
      value: "other",
      name: intl.formatMessage({ id: "other" }),
      valueUnformated: "Other",
    },
  ];
};

export const EducationType = (intl: any) => {
  return [
    {
      value: "business-school",
      name: intl.formatMessage({ id: "business-school" }),
    },
    {
      value: "engineering-school",
      name: intl.formatMessage({ id: "engineering-school" }),
    },
    { value: "iut", name: intl.formatMessage({ id: "iut" }) },
    {
      value: "university",
      name: intl.formatMessage({ id: "education_university" }),
    },
    { value: "ufr", name: intl.formatMessage({ id: "ufr" }) },
    { value: "other", name: intl.formatMessage({ id: "other" }) },
  ];
};

export const DaysOfTheWeek = (intl: any, weekendDays: boolean = true) => {
  const days = [
    { value: 1, name: intl.formatMessage({ id: "monday" }) },
    { value: 2, name: intl.formatMessage({ id: "tuesday" }) },
    { value: 3, name: intl.formatMessage({ id: "wednesday" }) },
    { value: 4, name: intl.formatMessage({ id: "thursday" }) },
    { value: 5, name: intl.formatMessage({ id: "friday" }) },
  ];

  if (weekendDays) {
    return [
      ...days,
      { value: 6, name: intl.formatMessage({ id: "saturday" }) },
      { value: 7, name: intl.formatMessage({ id: "sunday" }) },
    ];
  } else return days;
};

export const SessionTypes = (intl: any) => {
  return [
    { value: "session-0", name: intl.formatMessage({ id: "session-0" }) },
    {
      value: "group-session",
      name: intl.formatMessage({ id: "group-session" }),
    },
    {
      value: "private-session",
      name: intl.formatMessage({ id: "private-session" }),
    },
  ];
};

export const SessionRecurrenceType = (intl: any) => {
  return [
    { value: "one-time", name: intl.formatMessage({ id: "one-time" }) },
    { value: "weekly", name: intl.formatMessage({ id: "weekly" }) },
    { value: "bi-monthly", name: intl.formatMessage({ id: "bi-monthly" }) },
  ];
};

export const ReferenceList = (intl: any) => {
  return [
    { value: "university", name: intl.formatMessage({ id: "university" }) },
    { value: "high-school", name: intl.formatMessage({ id: "high-school" }) },
    { value: "company", name: intl.formatMessage({ id: "company" }) },
    {
      value: "external-event",
      name: intl.formatMessage({ id: "external-event" }),
    },
    { value: "website", name: intl.formatMessage({ id: "website" }) },
    {
      value: "national-service",
      name: intl.formatMessage({ id: "national-service" }),
    },
    {
      value: "social-network",
      name: intl.formatMessage({ id: "social-network" }),
    },
    {
      value: "internet-platform",
      name: intl.formatMessage({ id: "internet-platform" }),
    },
    {
      value: "previous-tutor",
      name: intl.formatMessage({ id: "previous-tutor" }),
    },
    {
      value: "word-of-mouth",
      name: intl.formatMessage({ id: "word-of-mouth" }),
    },
    { value: "other", name: intl.formatMessage({ id: "other" }) },
  ];
};

export const SubReferenceList = (intl: any) => {
  return [
    {
      value: "from-a-presentation",
      name: intl.formatMessage({ id: "from-a-presentation" }),
    },
    { value: "from-a-forum", name: intl.formatMessage({ id: "from-a-forum" }) },
    { value: "from-a-mail", name: intl.formatMessage({ id: "from-a-mail" }) },
    {
      value: "from-a-poster",
      name: intl.formatMessage({ id: "from-a-poster" }),
    },
    {
      value: "from-a-presentation-webinar",
      name: intl.formatMessage({ id: "from-a-presentation-webinar" }),
    },
    {
      value: "from-a-mail-or-intranet",
      name: intl.formatMessage({ id: "from-a-mail-or-intranet" }),
    },
    { value: "instagram", name: intl.formatMessage({ id: "instagram" }) },
    { value: "facebook", name: intl.formatMessage({ id: "facebook" }) },
    { value: "tiktok", name: intl.formatMessage({ id: "tiktok" }) },
    { value: "linkedin", name: intl.formatMessage({ id: "linkedin" }) },
    { value: "wenabi", name: intl.formatMessage({ id: "wenabi" }) },
    { value: "vendredi", name: intl.formatMessage({ id: "vendredi" }) },
    { value: "day-one", name: intl.formatMessage({ id: "day-one" }) },
    {
      value: "je-veux-aider",
      name: intl.formatMessage({ id: "je-veux-aider" }),
    },
    {
      value: "1jeune1mentor",
      name: intl.formatMessage({ id: "1jeune1mentor" }),
    },
    { value: "microdon", name: intl.formatMessage({ id: "microdon" }) },
    {
      value: "france-benevolat",
      name: intl.formatMessage({ id: "france-benevolat" }),
    },
    { value: "benevity", name: intl.formatMessage({ id: "benevity" }) },
    { value: "je-mengage", name: intl.formatMessage({ id: "je-mengage" }) },
    { value: "diffuz", name: intl.formatMessage({ id: "diffuz" }) },
    {
      value: "tous-benevoles",
      name: intl.formatMessage({ id: "tous-benevoles" }),
    },
    { value: "benevolt", name: intl.formatMessage({ id: "benevolt" }) },
  ];
};

export const UserStatusesList = (intl: any, config?: any): any => {
  let statuses = [
    { value: "active", name: intl.formatMessage({ id: "active" }) },
    { value: "deactivated", name: intl.formatMessage({ id: "deactivated" }) },
    { value: "on-hold", name: intl.formatMessage({ id: "on-hold" }) },
  ];

  if (config?.hasAll === true)
    statuses = [
      { value: "all", name: intl.formatMessage({ id: "all" }) },
      ...statuses,
    ];

  if (config?.isTutor === true)
    statuses = [
      ...statuses,
      {
        value: "commitment-end",
        name: intl.formatMessage({ id: "commitment-end" }),
      },
      {
        value: "preemptive-end",
        name: intl.formatMessage({ id: "preemptive-end" }),
      },
    ];

  return statuses;
};

export const TutorStatusesList = (intl: any) => {
  return [
    { value: "new", name: intl.formatMessage({ id: "new" }) },
    {
      value: "incomplete-relance-1",
      name: intl.formatMessage({ id: "incomplete-relance-1" }),
    },
    {
      value: "incomplete-relance-2",
      name: intl.formatMessage({ id: "incomplete-relance-2" }),
    },
    { value: "complete", name: intl.formatMessage({ id: "complete" }) },
    {
      value: "awaiting-student-1",
      name: intl.formatMessage({ id: "awaiting-student-1" }),
    },
    {
      value: "student-1-found",
      name: intl.formatMessage({ id: "student-1-found" }),
    },
    {
      value: "scheduled-session-0",
      name: intl.formatMessage({ id: "scheduled-session-0" }),
    },
    { value: "in-tutoring", name: intl.formatMessage({ id: "in-tutoring" }) },
    { value: "for-renewal", name: intl.formatMessage({ id: "for-renewal" }) },
    { value: "pause", name: intl.formatMessage({ id: "pause" }) },
    { value: "stop", name: intl.formatMessage({ id: "stop" }) },
    {
      value: "no-further-action",
      name: intl.formatMessage({ id: "no-further-action" }),
    },
  ];
};
export const TutorCreationEnum = (intl: any) => {
  return [
    { value: "admin", name: intl.formatMessage({ id: "administrator" }) },
    {
      value: "classic",
      name: intl.formatMessage({ id: "classic" }),
    },
  ];
};
export const StudentStatusesList = (intl: any) => {
  return [
    { value: "new", name: intl.formatMessage({ id: "new" }) },
    {
      value: "awaiting-tutor",
      name: intl.formatMessage({ id: "awaiting-tutor" }),
    },
    { value: "in-tutoring", name: intl.formatMessage({ id: "in-tutoring" }) },
    { value: "stop", name: intl.formatMessage({ id: "stop" }) },
  ];
};

export const StudentPartnerList = (intl: any) => {
  return [
    { value: "homeclasse-convention", name: "HomeClasse Convention" },
    { value: "local-de-blois", name: "Au local de Blois" },
    { value: "lycees-ad2e", name: "Lycées AD2E" },
    { value: "zupdefoot", name: "ZUPdeFOOT" },
    { value: "ase", name: "ASE" },
    { value: "Suzali", name: "Suzali" },
    { value: "1jeune1mentor", name: "1jeune1mentor" },
  ];
};

export const TutorPartnerList = (intl: any) => {
  return [
    { value: "homeclasse-convention", name: "HomeClasse Convention" },
    { value: "local-de-blois", name: "Au local de Blois" },
    { value: "lycees-ad2e", name: "Lycées AD2E" },
    { value: "zupdefoot", name: "ZUPdeFOOT" },
    { value: "ase", name: "ASE" },
    { value: "Suzali", name: "Suzali" },
    { value: "1jeune1mentor", name: "1jeune1mentor" },
  ];
};

export const ActiveInactiveList = (intl: any) => {
  return [
    { value: "active", name: intl.formatMessage({ id: "active" }) },
    { value: "deactivated", name: intl.formatMessage({ id: "deactivated" }) },
  ];
};

export const VscStatuses = (intl: any) => {
  return [
    { value: "active", name: intl.formatMessage({ id: "active" }) },
    { value: "deactivated", name: intl.formatMessage({ id: "deactivated" }) },
    {
      value: "commitment-end",
      name: intl.formatMessage({ id: "commitment-end" }),
    },
    {
      value: "preemptive-interruption",
      name: intl.formatMessage({ id: "preemptive-interruption" }),
    },
  ];
};

export const StudiesList = (intl: any) => {
  return [
    { value: "math", name: intl.formatMessage({ id: "math" }) },
    { value: "french", name: intl.formatMessage({ id: "french" }) },
    { value: "english", name: intl.formatMessage({ id: "english" }) },
    { value: "physics", name: intl.formatMessage({ id: "physics" }) },
    { value: "chemistry", name: intl.formatMessage({ id: "chemistry" }) },
    { value: "biology", name: intl.formatMessage({ id: "biology" }) },
  ];
};

export const TutorActivityTypes = (intl: any) => {
  return [
    {
      value: "pupil-high-school",
      name: intl.formatMessage({ id: "pupil-high-school" }),
    },

    { value: "employee", name: intl.formatMessage({ id: "employee" }) },
    { value: "public-agent", name: intl.formatMessage({ id: "public-agent" }) },
    { value: "freelance", name: intl.formatMessage({ id: "freelance" }) },
    { value: "retire", name: intl.formatMessage({ id: "retire" }) },
    {
      value: "searchin-for-a-job",
      name: intl.formatMessage({ id: "searchin-for-a-job" }),
    },
    { value: "unemployed", name: intl.formatMessage({ id: "unemployed" }) },
  ];
};

export const UserRolesList = (intl: any) => {
  return [
    { value: "tutor", name: intl.formatMessage({ id: "tutor" }) },
    { value: "parent", name: intl.formatMessage({ id: "parent" }) },
    { value: "student", name: intl.formatMessage({ id: "student" }) },
    {
      value: "super_administrator",
      name: intl.formatMessage({ id: "super_administrator" }),
    },
    { value: "manager", name: intl.formatMessage({ id: "manager" }) },
    { value: "coordinator", name: intl.formatMessage({ id: "coordinator" }) },
    { value: "vsc", name: intl.formatMessage({ id: "vsc" }) },
  ];
};

export const RecurrenceList = (intl: any) => {
  return [
    { value: "weekly", name: intl.formatMessage({ id: "weekly" }) },
    { value: "bi-monthly", name: intl.formatMessage({ id: "bi-monthly" }) },
    { value: "one-time", name: intl.formatMessage({ id: "one-time" }) },
  ];
};

export const ReportsStatusList = (intl: any) => {
  return [
    {
      value: ReportsStatus.toBeEntered,
      name: intl.formatMessage({ id: "to-be-entered" }),
    },
    {
      value: ReportsStatus.entered,
      name: intl.formatMessage({ id: "entered" }),
    },
    {
      value: ReportsStatus.confirmed,
      name: intl.formatMessage({ id: "confirmed" }),
    },
  ];
};

export const ReportsTypesList = (intl: any) => {
  return [
    {
      value: ReportsTypes.crReport,
      name: intl.formatMessage({ id: "cr-report" }),
    },
    {
      value: ReportsTypes.scReport,
      name: intl.formatMessage({ id: "sc-report" }),
    },
  ];
};

export const StatusOfEstablishments = (intl: any) => {
  return [
    {
      value: StatusOfEstablishment.ACTIVE,
      name: intl.formatMessage({ id: "active" }),
    },
    {
      value: StatusOfEstablishment.PROSPECT,
      name: intl.formatMessage({ id: "prospect" }),
    },
    {
      value: StatusOfEstablishment.INACTIVE,
      name: intl.formatMessage({ id: "inactive" }),
    },
  ];
};

export const HolidayStatusesList = (intl: any) => {
  return [
    { value: "allowed", name: intl.formatMessage({ id: "allowed" }) },
    { value: "not-allowed", name: intl.formatMessage({ id: "not-allowed" }) },
  ];
};

export const ListOfSchools = () => [
  {
    department: "41",
    city: "BLOIS",
    typeOfSchool: "Collège",
    nameOfSchool: "Bégon",
    valueOfSchool: "begon",
    coordinatorInCharge: "Marwane HAMMOUTI",
  },
  {
    department: "41",
    city: "BLOIS",
    typeOfSchool: "Collège",
    nameOfSchool: "Rabelais",
    valueOfSchool: "rabelais",
    coordinatorInCharge: "Marwane HAMMOUTI",
  },
  {
    department: "41",
    city: "BLOIS",
    typeOfSchool: "Collège",
    nameOfSchool: "Augustin",
    valueOfSchool: "augustin",
    coordinatorInCharge: "Marwane HAMMOUTI",
  },
  {
    department: "41",
    city: "BLOIS",
    typeOfSchool: "Collège",
    nameOfSchool: "Les Provinces",
    valueOfSchool: "les-provinces",
    coordinatorInCharge: "Marwane HAMMOUTI",
  },
  {
    department: "41",
    city: "Romorantin",
    typeOfSchool: "Collège",
    nameOfSchool: "Léonard de Vinci",
    valueOfSchool: "leonard-de-vinci",
    coordinatorInCharge: "Marwane HAMMOUTI",
  },
  {
    department: "41",
    city: "ONZAIN",
    typeOfSchool: "Collège",
    nameOfSchool: "Joseph Crocheton",
    valueOfSchool: "joseph-crocheton",
    coordinatorInCharge: "Marwane HAMMOUTI",
  },
  {
    department: "41",
    city: "BLOIS",
    typeOfSchool: "Collège",
    nameOfSchool: "Lycee Camille Claudel",
    valueOfSchool: "camille-claudel",
    coordinatorInCharge: "Marwane HAMMOUTI",
  },
  {
    department: "41",
    city: "BLOIS",
    typeOfSchool: "Collège",
    nameOfSchool: "Lycée Augustin",
    valueOfSchool: "augustin",
    coordinatorInCharge: "Marwane HAMMOUTI",
  },
  {
    department: "69",
    city: "Lyon 3",
    typeOfSchool: "Collège",
    nameOfSchool: "Raoul Dufy",
    valueOfSchool: "raoul-dufy",
    coordinatorInCharge: "Kéli MARTRENCHAR",
  },
  {
    department: "69",
    city: "Lyon 3",
    typeOfSchool: "Collège",
    nameOfSchool: "Professeur Dargent",
    valueOfSchool: "professeur-dargent",
    coordinatorInCharge: "Kéli MARTRENCHAR",
  },
  {
    department: "69",
    city: "Lyon 5",
    typeOfSchool: "Collège",
    nameOfSchool: "Charcot",
    valueOfSchool: "charcot",
    coordinatorInCharge: "Kéli MARTRENCHAR",
  },
  {
    department: "69",
    city: "Lyon 8",
    typeOfSchool: "Collège",
    nameOfSchool: "Mermoz",
    valueOfSchool: "mermoz",
    coordinatorInCharge: "Kéli MARTRENCHAR",
  },
  {
    department: "69",
    city: "Lyon 8",
    typeOfSchool: "Collège",
    nameOfSchool: "Victor Grignard",
    valueOfSchool: "victor-grignard",
    coordinatorInCharge: "Kéli MARTRENCHAR",
  },
  {
    department: "69",
    city: "Vénissieux",
    typeOfSchool: "Collège",
    nameOfSchool: "Paul Eluard",
    valueOfSchool: "paul-eluard",
    coordinatorInCharge: "Kéli MARTRENCHAR",
  },
  {
    department: "69",
    city: "Vénissieux",
    typeOfSchool: "Collège",
    nameOfSchool: "Michelet",
    valueOfSchool: "michelet",
    coordinatorInCharge: "Kéli MARTRENCHAR",
  },
  {
    department: "69",
    city: "Lyon 7",
    typeOfSchool: "Collège",
    nameOfSchool: "G. Rosset",
    valueOfSchool: "g-rosset",
    coordinatorInCharge: "Anais MAINGUES",
  },
  {
    department: "69",
    city: "Vaulx en Velin",
    typeOfSchool: "Collège",
    nameOfSchool: "P. Valdo",
    valueOfSchool: "p-valdo",
    coordinatorInCharge: "Anais MAINGUES",
  },
  {
    department: "69",
    city: "Vaulx en Velin",
    typeOfSchool: "Collège",
    nameOfSchool: "Ecole p. Makarenko B",
    valueOfSchool: "ecole-p-makarenko-b",
    coordinatorInCharge: "Anais MAINGUES",
  },
  {
    department: "69",
    city: "Vaulx en Velin",
    typeOfSchool: "Collège",
    nameOfSchool: "Ecole p. Anne Frank",
    valueOfSchool: "ecole-p-anne-frank",
    coordinatorInCharge: "Anais MAINGUES",
  },
  {
    department: "69",
    city: "Villeurbanne",
    typeOfSchool: "Collège",
    nameOfSchool: "Simone Lagrange",
    valueOfSchool: "simone-lagrange",
    coordinatorInCharge: "Anais MAINGUES",
  },
  {
    department: "69",
    city: "Villeurbanne",
    typeOfSchool: "Collège",
    nameOfSchool: "Les Iris",
    valueOfSchool: "les-iris",
    coordinatorInCharge: "Anais MAINGUES",
  },
  {
    department: "69",
    city: "Villeurbanne",
    typeOfSchool: "Collège",
    nameOfSchool: "Lamartine",
    valueOfSchool: "lamartine",
    coordinatorInCharge: "Anais MAINGUES",
  },
  {
    department: "69",
    city: "Villeurbanne",
    typeOfSchool: "Collège",
    nameOfSchool: "Jean Jaurès",
    valueOfSchool: "jean-jaures",
    coordinatorInCharge: "Anais MAINGUES",
  },
  {
    department: "69",
    city: "Lyon 7",
    typeOfSchool: "Collège",
    nameOfSchool: "Gisèle Halimi",
    valueOfSchool: "gisele-halimi",
    coordinatorInCharge: "Marie-Noelle",
  },
  {
    department: "69",
    city: "Lyon 9",
    typeOfSchool: "Collège",
    nameOfSchool: "Jean de Verrazane",
    valueOfSchool: "jean-de-verrazane",
    coordinatorInCharge: "Marie-Noelle",
  },
  {
    department: "75",
    city: "Paris 10",
    typeOfSchool: "Collège",
    nameOfSchool: "Françoise Seligmann",
    valueOfSchool: "francoise-seligmann",
    coordinatorInCharge: "Jules MONIN",
  },
  {
    department: "75",
    city: "Paris 12",
    typeOfSchool: "Collège",
    nameOfSchool: "Paul Verlaine",
    valueOfSchool: "paul-verlaine",
    coordinatorInCharge: "Jules MONIN",
  },
  {
    department: "75",
    city: "Paris 13",
    typeOfSchool: "Collège",
    nameOfSchool: "Evariste Galois",
    valueOfSchool: "evariste-galois",
    coordinatorInCharge: "Jules MONIN",
  },
  {
    department: "75",
    city: "Paris 13",
    typeOfSchool: "Collège",
    nameOfSchool: "Gustave Flaubert",
    valueOfSchool: "gustave-flaubert",
    coordinatorInCharge: "Jules MONIN",
  },
  {
    department: "75",
    city: "Paris 13",
    typeOfSchool: "Collège",
    nameOfSchool: "Thomas Mann",
    valueOfSchool: "thomas-mann",
    coordinatorInCharge: "Jules MONIN",
  },
  {
    department: "75",
    city: "Paris 14",
    typeOfSchool: "Collège",
    nameOfSchool: "François Villon",
    valueOfSchool: "francois-villon",
    coordinatorInCharge: "Jules MONIN",
  },
  {
    department: "75",
    city: "Paris 17",
    typeOfSchool: "Collège",
    nameOfSchool: "Boris Vian",
    valueOfSchool: "boris-vian",
    coordinatorInCharge: "Jules MONIN",
  },
  {
    department: "75",
    city: "Paris 18",
    typeOfSchool: "Collège",
    nameOfSchool: "Aimé Césaire",
    valueOfSchool: "aime-cesaire",
    coordinatorInCharge: "Jules MONIN",
  },
  {
    department: "75",
    city: "Paris 18",
    typeOfSchool: "Collège",
    nameOfSchool: "Daniel Mayer",
    valueOfSchool: "daniel-mayer",
    coordinatorInCharge: "Jules MONIN",
  },
  {
    department: "75",
    city: "Paris 18",
    typeOfSchool: "Collège",
    nameOfSchool: "Hector Berlioz",
    valueOfSchool: "hector-berlioz",
    coordinatorInCharge: "Jules MONIN",
  },
  {
    department: "75",
    city: "Paris 20",
    typeOfSchool: "Collège",
    nameOfSchool: "Colette Besson",
    valueOfSchool: "colette-besson",
    coordinatorInCharge: "Jules MONIN",
  },
  {
    department: "78",
    city: "Achères",
    typeOfSchool: "Collège",
    nameOfSchool: "Camille du Gast",
    valueOfSchool: "camille-du-gast",
    coordinatorInCharge: "Alain WODEY",
  },
  {
    department: "78",
    city: "Chanteloup les Vignes",
    typeOfSchool: "Collège",
    nameOfSchool: "Magellan",
    valueOfSchool: "magellan",
    coordinatorInCharge: "Alain WODEY",
  },
  {
    department: "78",
    city: "Chanteloup les Vignes",
    typeOfSchool: "Collège",
    nameOfSchool: "Cassin",
    valueOfSchool: "cassin",
    coordinatorInCharge: "Alain WODEY",
  },
  {
    department: "78",
    city: "Elancourt",
    typeOfSchool: "Collège",
    nameOfSchool: "de l'Agiot",
    valueOfSchool: "de-l-agiot",
    coordinatorInCharge: "Sylvie",
  },
  {
    department: "78",
    city: "Houilles",
    typeOfSchool: "Collège",
    nameOfSchool: "Guy de Maupassant",
    valueOfSchool: "guy-de-maupassant",
    coordinatorInCharge: "Thomas VILLARD",
  },
  {
    department: "78",
    city: "Les Mureaux",
    typeOfSchool: "Collège",
    nameOfSchool: "Jules Verne",
    valueOfSchool: "jules-verne",
    coordinatorInCharge: "Alain WODEY",
  },
  {
    department: "78",
    city: "Les Mureaux",
    typeOfSchool: "Collège",
    nameOfSchool: "Paul Verlaine",
    valueOfSchool: "paul-verlaine",
    coordinatorInCharge: "Alain WODEY",
  },
  {
    department: "78",
    city: "Mantes la Ville",
    typeOfSchool: "Collège",
    nameOfSchool: "Les Plaisances",
    valueOfSchool: "les-plaisances",
    coordinatorInCharge: "Alain WODEY",
  },
  {
    department: "78",
    city: "Montigny le Bretonneux",
    typeOfSchool: "Collège",
    nameOfSchool: "Alberto Giacometti",
    valueOfSchool: "alberto-giacometti",
    coordinatorInCharge: "Sylvie",
  },
  {
    department: "78",
    city: "Conflans Sainte Honorine",
    typeOfSchool: "Collège",
    nameOfSchool: "Les Hautes Rayes",
    valueOfSchool: "les-hautes-rayes",
    coordinatorInCharge: "Alain WODEY",
  },
  {
    department: "78",
    city: "Sartrouville",
    typeOfSchool: "Collège",
    nameOfSchool: "Louis Paulhan",
    valueOfSchool: "louis-paulhan",
    coordinatorInCharge: "Thomas VILLARD",
  },
  {
    department: "78",
    city: "Versailles",
    typeOfSchool: "Collège",
    nameOfSchool: "JP Rameau",
    valueOfSchool: "jp-rameau",
    coordinatorInCharge: "Sylvie",
  },
  {
    department: "91",
    city: "Etampes",
    typeOfSchool: "Collège",
    nameOfSchool: "de Guinette",
    valueOfSchool: "de-guinette",
    coordinatorInCharge: "Anne-Françoise BABIN HAMELIN",
  },
  {
    department: "91",
    city: "Les Ulis",
    typeOfSchool: "Collège",
    nameOfSchool: "Mondétour",
    valueOfSchool: "mondétour",
    coordinatorInCharge: "Anne-Françoise BABIN HAMELIN",
  },
  {
    department: "91",
    city: "Les Ulis",
    typeOfSchool: "Collège",
    nameOfSchool: "Aimé Césaire",
    valueOfSchool: "aime-cesaire",
    coordinatorInCharge: "Anne-Françoise BABIN HAMELIN",
  },
  {
    department: "91",
    city: "Longjumeau",
    typeOfSchool: "Collège",
    nameOfSchool: "Louis Pasteur",
    valueOfSchool: "louis-pasteur",
    coordinatorInCharge: "Anne-Françoise BABIN HAMELIN",
  },
  {
    department: "91",
    city: "Massy",
    typeOfSchool: "Collège",
    nameOfSchool: "Blaise Pascal",
    valueOfSchool: "blaise-pascal",
    coordinatorInCharge: "Aude",
  },
  {
    department: "91",
    city: "Massy",
    typeOfSchool: "Collège",
    nameOfSchool: "Diderot",
    valueOfSchool: "diderot",
    coordinatorInCharge: "Aude",
  },
  {
    department: "91",
    city: "Massy",
    typeOfSchool: "Collège",
    nameOfSchool: "Gérard Philipe",
    valueOfSchool: "gerard-philipe",
    coordinatorInCharge: "Aude",
  },
  {
    department: "91",
    city: "Morangis",
    typeOfSchool: "Collège",
    nameOfSchool: "Michel Vignaud",
    valueOfSchool: "michel-vignaud",
    coordinatorInCharge: "Anne-Françoise BABIN HAMELIN",
  },
  {
    department: "91",
    city: "Palaiseau",
    typeOfSchool: "Collège",
    nameOfSchool: "Joseph Bara",
    valueOfSchool: "joseph-bara",
    coordinatorInCharge: "Anne-Françoise BABIN HAMELIN",
  },
  {
    department: "91",
    city: "Palaiseau",
    typeOfSchool: "Collège",
    nameOfSchool: "César Franck",
    valueOfSchool: "cesar-franck",
    coordinatorInCharge: "Anne-Françoise BABIN HAMELIN",
  },
  {
    department: "91",
    city: "Saint Michel sur Orge",
    typeOfSchool: "Collège",
    nameOfSchool: "Nicolas Boileau",
    valueOfSchool: "nicolas-boileau",
    coordinatorInCharge: "Anne-Françoise BABIN HAMELIN",
  },
  {
    department: "91",
    city: "Ris-Orangis",
    typeOfSchool: "Collège",
    nameOfSchool: "Albert Camus",
    valueOfSchool: "albert-camus",
    coordinatorInCharge: "Anne-Françoise BABIN HAMELIN",
  },
  {
    department: "92",
    city: "Antony",
    typeOfSchool: "Collège",
    nameOfSchool: "Anne Frank",
    valueOfSchool: "anne-frank",
    coordinatorInCharge: "Aude",
  },
  {
    department: "92",
    city: "Asnières sur Seine",
    typeOfSchool: "Collège",
    nameOfSchool: "André Malraux",
    valueOfSchool: "andre-malraux",
    coordinatorInCharge: "Sébastien",
  },
  {
    department: "92",
    city: "Bagneux",
    typeOfSchool: "Collège",
    nameOfSchool: "Henri Barbusse",
    valueOfSchool: "henri-barbusse",
    coordinatorInCharge: "Aude",
  },
  {
    department: "92",
    city: "Bagneux",
    typeOfSchool: "Collège",
    nameOfSchool: "Joliot Curie",
    valueOfSchool: "joliot-curie",
    coordinatorInCharge: "Aude",
  },
  {
    department: "92",
    city: "Bagneux",
    typeOfSchool: "Collège",
    nameOfSchool: "Romain Rolland",
    valueOfSchool: "romain-rolland",
    coordinatorInCharge: "Aude",
  },
  {
    department: "92",
    city: "Bourg la Reine",
    typeOfSchool: "Collège",
    nameOfSchool: "Evariste Galois",
    valueOfSchool: "evariste-galois",
    coordinatorInCharge: "Aude",
  },
  {
    department: "92",
    city: "Chatenay Malabry",
    typeOfSchool: "Collège",
    nameOfSchool: "Léonard de Vinci",
    valueOfSchool: "leonard-de-vinci",
    coordinatorInCharge: "Aude",
  },
  {
    department: "92",
    city: "Chatenay Malabry",
    typeOfSchool: "Collège",
    nameOfSchool: "Thomas Masaryk",
    valueOfSchool: "thomas-masaryk",
    coordinatorInCharge: "Aude",
  },
  {
    department: "92",
    city: "Clichy",
    typeOfSchool: "Collège",
    nameOfSchool: "Jean Jaurès",
    valueOfSchool: "jean-jaures",
    coordinatorInCharge: "Sébastien",
  },
  {
    department: "92",
    city: "Colombes",
    typeOfSchool: "Collège",
    nameOfSchool: "Jean-Baptiste Clément",
    valueOfSchool: "jean-baptiste-clement",
    coordinatorInCharge: "Sébastien",
  },
  {
    department: "92",
    city: "Colombes",
    typeOfSchool: "Collège",
    nameOfSchool: "Marguerite Duras",
    valueOfSchool: "marguerite-duras",
    coordinatorInCharge: "Sébastien",
  },
  {
    department: "92",
    city: "Nanterre",
    typeOfSchool: "Collège",
    nameOfSchool: "André Doucet",
    valueOfSchool: "andre-doucet",
    coordinatorInCharge: "Thomas VILLARD",
  },
  {
    department: "92",
    city: "Nanterre",
    typeOfSchool: "Collège",
    nameOfSchool: "Paul Eluard",
    valueOfSchool: "paul-eluard",
    coordinatorInCharge: "Thomas VILLARD",
  },
  {
    department: "92",
    city: "Villeneuve la Garenne",
    typeOfSchool: "Collège",
    nameOfSchool: "Pompidou",
    valueOfSchool: "pompidou",
    coordinatorInCharge: "Sébastien",
  },
  {
    department: "95",
    city: "Argenteuil",
    typeOfSchool: "Collège",
    nameOfSchool: "Ariane",
    valueOfSchool: "ariane",
    coordinatorInCharge: "Sébastien",
  },
  {
    department: "95",
    city: "Argenteuil",
    typeOfSchool: "Collège",
    nameOfSchool: "Rousseau",
    valueOfSchool: "rousseau",
    coordinatorInCharge: "Sébastien",
  },
  {
    department: "95",
    city: "Bezons",
    typeOfSchool: "Collège",
    nameOfSchool: "Henri Wallon",
    valueOfSchool: "henri-wallon",
    coordinatorInCharge: "Alain WODEY",
  },
  {
    department: "95",
    city: "Bezons",
    typeOfSchool: "Collège",
    nameOfSchool: "Gabriel Peri",
    valueOfSchool: "gabriel-peri",
    coordinatorInCharge: "Alain WODEY",
  },
  {
    department: "95",
    city: "Cergy",
    typeOfSchool: "Collège",
    nameOfSchool: "Les Explorateurs",
    valueOfSchool: "les-explorateurs",
    coordinatorInCharge: "Thomas VILLARD",
  },
  {
    department: "95",
    city: "Cergy",
    typeOfSchool: "Collège",
    nameOfSchool: "Les Touleuses",
    valueOfSchool: "les-touleuses",
    coordinatorInCharge: "Thomas VILLARD",
  },
  {
    department: "95",
    city: "Ermont",
    typeOfSchool: "Collège",
    nameOfSchool: "Jules Ferry",
    valueOfSchool: "jules-ferry",
    coordinatorInCharge: "Sébastien",
  },
  {
    department: "95",
    city: "Garges-lès-Gonèsse",
    typeOfSchool: "Collège",
    nameOfSchool: "Paul Eluard",
    valueOfSchool: "paul-eluard",
    coordinatorInCharge: "Sébastien",
  },
  {
    department: "95",
    city: "Pontoise",
    typeOfSchool: "Collège",
    nameOfSchool: "Simone Veil",
    valueOfSchool: "simone-veil",
    coordinatorInCharge: "Thomas VILLARD",
  },
  {
    department: "95",
    city: "St Ouen L'Aumône",
    typeOfSchool: "Collège",
    nameOfSchool: "Marcel Pagnol",
    valueOfSchool: "marcel-pagnol",
    coordinatorInCharge: "Thomas VILLARD",
  },
  {
    department: "75",
    city: "PARIS 10",
    typeOfSchool: "Lycée",
    nameOfSchool: "Marie-Laurencin",
    valueOfSchool: "marie-laurencin",
    coordinatorInCharge: "Stéphanie FAWAZ",
  },
  {
    department: "75",
    city: "PARIS 20",
    typeOfSchool: "Lycée",
    nameOfSchool: "Charles de Gaulle",
    valueOfSchool: "charles-de-gaulle",
    coordinatorInCharge: "Stéphanie FAWAZ",
  },
  {
    department: "75",
    city: "PARIS 19",
    typeOfSchool: "Lycée",
    nameOfSchool: "Diderot",
    valueOfSchool: "diderot",
    coordinatorInCharge: "Stéphanie FAWAZ",
  },
  {
    department: "75",
    city: "PARIS 9",
    typeOfSchool: "Lycée",
    nameOfSchool: "Jacques Decour",
    valueOfSchool: "jacques-decour",
    coordinatorInCharge: "Stéphanie FAWAZ",
  },
  {
    department: "75",
    city: "PARIS 18",
    typeOfSchool: "Lycée",
    nameOfSchool: "Camille Jenatzy",
    valueOfSchool: "camille-jenatzy",
    coordinatorInCharge: "Stéphanie FAWAZ",
  },
  {
    department: "93",
    city: "SAINT-DENIS",
    typeOfSchool: "Lycée",
    nameOfSchool: "Frédéric Bartholdi",
    valueOfSchool: "frederic-bartholdi",
    coordinatorInCharge: "Stéphanie FAWAZ",
  },
  {
    department: "93",
    city: "BONDY",
    typeOfSchool: "Lycée",
    nameOfSchool: "Madeleine Vionnet",
    valueOfSchool: "madeleine-vionnet",
    coordinatorInCharge: "Stéphanie FAWAZ",
  },
  {
    department: "93",
    city: "BONDY",
    typeOfSchool: "Lycée",
    nameOfSchool: "Jean Renoir",
    valueOfSchool: "jean-renoir",
    coordinatorInCharge: "Stéphanie FAWAZ",
  },
  {
    department: "93",
    city: "FONTENAY SOUS BOIS",
    typeOfSchool: "Lycée",
    nameOfSchool: "Jules Michelet",
    valueOfSchool: "jules-michelet",
    coordinatorInCharge: "Stéphanie FAWAZ",
  },
  {
    department: "93",
    city: "ROSNY SOUS BOIS",
    typeOfSchool: "Lycée",
    nameOfSchool: "Charles de Gaulle",
    valueOfSchool: "charles-de-gaulle",
    coordinatorInCharge: "Stéphanie FAWAZ",
  },
  {
    department: "75",
    city: "PARIS 14",
    typeOfSchool: "Lycée",
    nameOfSchool: "Raspail",
    valueOfSchool: "raspail",
    coordinatorInCharge: "Stéphanie FAWAZ",
  },
  {
    department: null,
    city: null,
    typeOfSchool: null,
    nameOfSchool: "Other",
    valueOfSchool: "other",
    coordinatorInCharge: null,
  },
];

export const PlaceOrRooms: { id: string; name: string }[] = [
  { id: "bce456Ci", name: "Domicile" },
  { id: "qlc116kv", name: "Etablissement scolaire" },
  { id: "rcl232xf", name: "Club de sport" },
  { id: "ehd523qa", name: "Autres" },
];
