import TutorPages from "./TutorPages";
import ParentPages from "./ParentPages";
import StudentPages from "./StudentPages";
import AuthPages from "./AuthPages";
import SessionPages from "./SessionPages";
import AdminPages from "./AdminPages";
import ManagerPages from "./ManagerPages";
import CoordinatorPages from "./CoordinatorPages";
import VSCPages from "./VSCPages";

import { AuthPaths, UserRoles } from "../../utils/const";
import { lazy } from "react";

let Routes: any;

const DefaultPage = [
  {
    path: AuthPaths.HOME,
    component: lazy(() => import("../../view/home/index")),
    layout: "FullLayout",
  },
];

// @ts-ignore
const user = JSON.parse(localStorage?.getItem("user")) || {};

switch (user?.userRole) {
  case UserRoles.TUTOR:
    Routes = [...AuthPages, ...TutorPages, ...SessionPages];
    break;

  case UserRoles.PARENT:
    Routes = [...AuthPages, ...ParentPages, ...SessionPages];
    break;

  case UserRoles.STUDENT:
    Routes = [...AuthPages, ...StudentPages, ...SessionPages];
    break;

  case UserRoles.ADMIN:
    Routes = [...AuthPages, ...AdminPages, ...SessionPages];
    break;

  case UserRoles.MANAGER:
    Routes = [...AuthPages, ...ManagerPages, ...SessionPages];
    break;

  case UserRoles.COORDINATOR:
    Routes = [...AuthPages, ...CoordinatorPages, ...SessionPages];
    break;

  case UserRoles.VSC:
    Routes = [...AuthPages, ...VSCPages, ...SessionPages];
    break;

  default:
    Routes = [...DefaultPage, ...AuthPages];
    break;
}

export { Routes };
