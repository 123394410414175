import Sidebar from "./components/menu/Sidebar";
import MenuHeader from "./components/header";
import ScrollTop from "./components/scroll-to-top";

import { useState } from "react";
import { useSelector } from "react-redux";
import { Layout, Row, Col } from "antd";
import { useLocation } from "react-router-dom";
import { UserCreatedBy, UserRoles, UserStatus } from "../utils/const";
import useLocalStorage from "../hooks/useLocalStorage";

const { Content } = Layout;

export default function VerticalLayout(props: any) {
  const { children } = props;
  const [visible, setVisible] = useState(false);

  const [user] = useLocalStorage("user", {});

  const shouldRedirectTutor =
    user?.userRole === UserRoles.TUTOR &&
    user?.status !== UserStatus.ACTIVE &&
    user?.createdBy !== UserCreatedBy.ADMIN &&
    user?.status !== UserStatus.COMMITMENT_END &&
    user?.status !== UserStatus.PREEMPTIVE_END;
  const shouldRedirectParentToOnboarding =
    user?.userRole === UserRoles.PARENT && user?.status !== UserStatus.ACTIVE;

  //@ts-ignore
  const customise = useSelector((state) => state.customise);

  return (
    <Layout className="hp-app-layout">
      {shouldRedirectTutor || shouldRedirectParentToOnboarding ? (
        <></>
      ) : (
        <Sidebar visible={visible} setVisible={setVisible} />
      )}
      <Layout className="hp-bg-black-20 hp-bg-color-dark-90 hp-position-relative">
        <MenuHeader setVisible={setVisible} />

        <Content className="hp-content-main">
          <Row justify="center">
            {customise.contentWidth === "full" && (
              <Col xxl={24} xl={24} span={24}>
                {children}
              </Col>
            )}

            {customise.contentWidth === "boxed" && (
              <Col className="hp-w-100" style={{ maxWidth: 936 }}>
                {children}
              </Col>
            )}
          </Row>
        </Content>
      </Layout>
      <ScrollTop />
    </Layout>
  );
}
