import { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { theme } from "../redux/customise/customiseActions";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { Routes } from "./routes";
//@ts-ignore
import { motion } from "framer-motion/dist/framer-motion";

import VerticalLayout from "../layout/VerticalLayout";
import InternalSystemLayout from "../layout/InternalSystemLayout";
import HorizontalLayout from "../layout/HorizontalLayout";
import FullLayout from "../layout/FullLayout";
import Error404 from "../view/errors/404";
import PrivateRoute from "./PrivateRoute";
import Privacy from "../view/privacy";
import Terms from "../view/terms";
import DataDeletion from "../view/data-deletion";
import Home from "../view/home";
import useLocalStorage from "../hooks/useLocalStorage";

export default function Router() {
  //@ts-ignore
  const customise = useSelector((state) => state.customise);
  const dispatch = useDispatch();
  const location = useHistory();
  const [user] = useLocalStorage("user", {});

  let themeLocal: any;

  useEffect(() => {
    if (localStorage) themeLocal = localStorage.getItem("theme");

    if (themeLocal === "light" || themeLocal === "dark") {
      //@ts-ignore
      document.querySelector("body").classList.add(themeLocal);
      dispatch(theme(themeLocal));
    } else {
      //@ts-ignore
      document.querySelector("body").classList.add(customise.theme);
      dispatch(theme(customise.theme));
    }
  }, []);

  useEffect(() => {
    //@ts-ignore
    if (customise.direction == "ltr")
      document.querySelector("html")?.setAttribute("dir", "ltr");
    //@ts-ignore
    else if (customise.direction == "rtl")
      document.querySelector("html")?.setAttribute("dir", "rtl");
  }, []);

  useEffect(() => {
    if (location.location.search == "?theme=dark") {
      localStorage.setItem("theme", "dark");
      themeLocal = "dark";
    } else if (location.location.search == "?theme=light") {
      localStorage.setItem("theme", "light");
      themeLocal = "light";
    }

    //@ts-ignore
    if (location.location.search == "?direction=ltr")
      document.querySelector("html")?.setAttribute("dir", "ltr");
    //@ts-ignore
    else if (location.location.search == "?direction=rtl")
      document.querySelector("html")?.setAttribute("dir", "rtl");
  }, []);

  const DefaultLayout = customise.layout;
  const Layouts = {
    VerticalLayout,
    InternalSystemLayout,
    HorizontalLayout,
    FullLayout,
  };

  const LayoutRoutesAndPaths = (layout: any) => {
    const LayoutRoutes: any = [];
    const LayoutPaths: any = [];
    const RedirectPaths: any = [];

    if (Routes) {
      Routes.filter(
        (route: any) =>
          route.layout === layout &&
          (LayoutRoutes.push(route),
          LayoutPaths.push(route.path),
          RedirectPaths.push(route?.redirectPath)),
      );
    }

    return { LayoutRoutes, LayoutPaths, RedirectPaths };
  };

  const ResolveRoutes = () => {
    return Object.keys(Layouts).map((layout, index) => {
      const { LayoutRoutes, LayoutPaths } = LayoutRoutesAndPaths(layout);

      let LayoutTag: any;

      if (DefaultLayout == "HorizontalLayout") {
        if (layout == "VerticalLayout") LayoutTag = Layouts["HorizontalLayout"];
        //@ts-ignore
        else LayoutTag = Layouts[layout];
      }
      //@ts-ignore
      else LayoutTag = Layouts[layout];

      return (
        <Route exact path={LayoutPaths} key={index}>
          <LayoutTag>
            <Switch>
              {LayoutRoutes.map((route: any) => {
                return (
                  <PrivateRoute
                    key={route.path}
                    path={route.path}
                    redirectPath={route.redirectPath}
                    exact
                    render={(props) => {
                      return (
                        <Suspense fallback={null}>
                          {route.layout === "FullLayout" ? (
                            <route.component {...props} />
                          ) : (
                            <motion.div
                              initial={{ opacity: 0, y: 50 }}
                              animate={{ opacity: 1, y: 0 }}
                              transition={{
                                type: "spring",
                                duration: 0.5,
                                delay: 0.5,
                              }}
                            >
                              <route.component {...props} />
                            </motion.div>
                          )}
                        </Suspense>
                      );
                    }}
                  />
                );
              })}
            </Switch>
          </LayoutTag>
        </Route>
      );
    });
  };

  return (
    <BrowserRouter>
      <Switch>
        {ResolveRoutes()}

        {/*  Global Routes */}
        <Route path="/privacy">
          <Privacy />
        </Route>

        <Route path="/">
          <Redirect to="/choose" />
        </Route>

        <Route path="/terms">
          <Terms />
        </Route>

        <Route path="/data-deletion">
          <DataDeletion />
        </Route>

        <Route path="/terms">
          <Terms />
        </Route>

        <Route path="*">
          <Error404 />
        </Route>
        {/*  Global Routes */}
      </Switch>
    </BrowserRouter>
  );
}
